import React, { Component } from "react";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  FieldGroup,
  FieldControl,
  Validators,
  FormBuilder,
} from "react-reactive-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox, FormHelperText, MenuItem, Select } from "@material-ui/core";
import LoaderSpinner from "../../commons/uicomponents/loader";
import ConfirmAlert from "../../commons/uicomponents/confirm";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import EmptyCard from "../../commons/uicomponents/emptycard";
import {
  deleteData,
  getData,
  saveData,
  updateData,
} from "../../dataProvider/apiHandler";
import chevronDown from "../../assets/images/chevron-down.png";
import Whatsapp from "../../assets/images/watsapp.png";
import fbmsngr from "../../assets/images/fbmsngr.png";
import slack from "../../assets/images/slack.png";
import line from "../../assets/images/line.png";
import telegram from "../../assets/images/telegram.png";
import webchat from "../../assets/images/webchat.png";
import jrnyEdit from "../../assets/images/jrny-edit.png";
import calender from "../../assets/images/calender.png";
import DeleteIcon from "../../assets/images/subtract.png";
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import "./project.scss";
import OAuthTwoDotO from "./jwtForms/oauthTwoO";
import FindError from "../../dataProvider/errorHandler";
import eyeIcon from "../../assets/images/visibility.png";
import eyeBlockIcon from "../../assets/images/visibility_off.png";
import Pagination from "../../commons/pagination";

const serverUrl = window._env_.REACT_APP_SXP_URL;
const parseServerUrl = window._env_.REACT_APP_PARSE_DASHBOARD_URL;
const projectInnerTabs = ["Configure Roles", "Configure Languages", "Configure Jwt Credentials", "Configure Dashboard ID"];

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allProjects: [],
      expansionJrnyPanelOpen: false,
      confirmAlert: false,
      confirmAlertChild: false,
      assignedUsers: [],
      allUsersList: [],
      allRoles: [],
      projectId: "",
      projectIdToRemoveUser: "",
      removeUserEmail: "",
      displayToast: false,
      isAdmin: localStorage.getItem("isAdmin"),
      value: 0,
      langArray: [],
      projectLanguages: [],
      languageOptions: [],
      chosenLang: "",
      projectName: "",
      projectNamePopup: false,
      defaultInnerTab: "Configure Roles",
      newVariable: false,
      newLangConfig: false,
      userEmail: '',
      assignUserBlock: false,
      assignNewUserEmail:'',
      nonSocialData :[],
      selectedNonSocialData:"",
      jwtCredentials:{},
      masterKey:'',
      masterKeyPopup:false,
      masterKeyType:'password',
      showEye:false,
      rowPageSize: localStorage.getItem("rowPerPage"),
      totalPages: "",
      pageNo: 1,
      setDahboardId:'',
    };
    this.projectTitleEnter = this.projectTitleEnter.bind(this);
  }

  componentDidMount = () => {
    this.loadAllProjects();
    this.LanguageList();
    this.nonSocialOauth();
  };


  conversationFormBuilder = FormBuilder.group({
    name: ["", Validators.required],
    description: ["", Validators.required],
  });

  switchJrnyExpansionpanel(index, id) {
    this.setState({ conversationID: "" });
    if (this.state.journeyIndex === index) {
      this.setState({
        expansionJrnyPanelOpen: false,
        journeyIndex: "",
      });
    } else {
      this.setState({
        expansionJrnyPanelOpen: true,
        journeyIndex: index,
        convList: true,
        newConv: false,
        setDahboardId:'',
      });
      this.getProjectLangs(id);
      this.updateJwtCredToProj(id);
    }
    this.getProjectAssignedUsers(id);
    this.loadAllRoles(id);
    this.loadAllUsers(id);
  }

  cancelExpand = () => {
    this.setState({
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      value: 0,
    });
  };

  loadAllProjects = async (paramset) => {
    const initialVal = {
      paginate: true,
      perPage: localStorage.getItem("rowPerPage"),
    }
    const paramsets = {
      ...initialVal,
      ...paramset,
    }
    try {
      this.setState({loadingCard: true})
      const allOption = {
        url: serverUrl + "projects/"+ localStorage.getItem('pId')+ "/projects-details",
        params:{ ...paramsets }
      };
      let initialLoad = await getData(allOption);
      this.setState({
        allProjects: initialLoad.data.data.docs,
        totalPages: Math.ceil(initialLoad.data.data.total / initialLoad.data.data.limit),
        loadingCard: false,
      });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  };


  loadAllRoles = async (id) => {
    try {
      const allOption = {
        url: serverUrl + "projects/roles/" + id,
      };
      let initialLoad = await getData(allOption);
      this.setState({ allRoles: initialLoad.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  loadAllUsers = async (id) => {
    try {
      const allOption = {
        url: serverUrl + "projects/" + id + "/users-list",
      };
      let initialLoad = await getData(allOption);
      this.setState({ allUsersList: initialLoad.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  nonSocialOauth = async () =>{
    try {
      const allOption = {
        url: serverUrl + "non-social-channels-auth",
      };
      let initialLoad = await getData(allOption);
      this.setState({ nonSocialData: initialLoad.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  }

  updateJwtCredToProj = (id) => {
    let allProjs = this.state.allProjects;
    let getProjJwtCred = allProjs.find(x => x.id === id );
    this.setState({jwtCredentials: {...getProjJwtCred.nonSocialChannelAuthCredentials}})
  }

  LanguageList = async () => {
    try {
      const allOption = {
        url: serverUrl + "languages",
      };
      let initialLoad = await getData(allOption);
      this.setState({ langArray: initialLoad.data.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  getProjectAssignedUsers = async (id) => {
    try {
      const allOption = {
        url: serverUrl +"projects/" + id + "/users",
        params: { addedUsers: true },
      };
      const {data} = await getData(allOption);
      this.setState({ assignedUsers: data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  getProjectLangs = async (id) => {
    try {
      const allOptions = {
        url: serverUrl + "languages/" + id,
      };
      const res = await getData(allOptions);
      res.data.data
        ? this.setState({
            projectLanguages: res.data.data,
          })
        : this.setState({ projectLanguages: [] });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  addNewProject = () => {
    this.setState({ newVariable: true, newLangConfig: false, jwtCredentials:{} });
  };

  hideNewProj = () => {
    this.setState({ newVariable: false });
  };

  getlanguageOptions = async () => {
    this.setState({ newLangConfig: true, newVariable: false });
    try {
      const allOption = {
        url: serverUrl + "languages/options",
      };
      const res = await getData(allOption);
      this.setState({ languageOptions: res.data.data });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  createProjectForm = async (e, val) => {
    e.preventDefault();
    val.active = true;
    val.nonSocialChannelAuthType = this.state.selectedNonSocialData;
    val = {...val, ...this.state.jwtCredentials}
    if(this.state.jwtCredentials?.nonSocialChannelAuthCredentials){
      for(var objKey in this.state.jwtCredentials?.nonSocialChannelAuthCredentials)
      {
        if (this.state.jwtCredentials?.nonSocialChannelAuthCredentials[objKey] === "") {
          this.setState({
            displayToast: true,
            messageToast: objKey +"is required field",
            severity: "error",
          });
          return;
        }
      }
    }
    else{
      for(var objKey in this.state.jwtCredentials)
      {
        if (this.state.jwtCredentials?.[objKey] === "") {
          this.setState({
            displayToast: true,
            messageToast: objKey +"is required field",
            severity: "error",
          });
          return;
        }
      }
    }
    try {
      const allOptions = {
        url: serverUrl + `projects`,
        body: val,
      };
      await saveData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Project Created Successfully",
        severity: "success",
      });
      this.loadAllProjects();
      this.hideNewProj();
    } catch (e) {
      const errorObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errorObj.message ? errorObj.message: 'Some fields are missing',
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  handleDashboardId = (e, index) => {
    let allLoadedProjects = [...this.state.allProjects]
    allLoadedProjects[index].dashboardId = e.target.value;
    this.setState({allProjects :allLoadedProjects})
  }

  updateProjectDashboard = async(item) => {
    if(item.dashboardId === "")
    {
      this.setState({
        displayToast: true,
        messageToast: "Dashboard ID is Required",
        severity: "error",
      });
    }
    else
    {
      try{
        const allOptions = {
          url: serverUrl + `projects/` + item.id,
          body: {dashboardId: item.dashboardId},
        };
        await updateData(allOptions);
        this.setState({
          displayToast: true,
          messageToast: "DashboardId Updated Successfully",
          severity: "success",
          setDahboardId:'',
        });
        this.loadAllProjects();
      }
      catch(e){
        const errorObj = FindError(e)
        this.setState({
          displayToast: true,
          messageToast: errorObj.message,
          severity: "error",
          confirmAlert: false,
        });
      }
    }

  }

  updateJWTData = async(id) => {
    if(this.state.jwtCredentials?.nonSocialChannelAuthCredentials){
      for(var objKey in this.state.jwtCredentials?.nonSocialChannelAuthCredentials)
      {
        if (this.state.jwtCredentials?.nonSocialChannelAuthCredentials[objKey] === "") {
          this.setState({
            displayToast: true,
            messageToast: objKey +"is required field",
            severity: "error",
          });
          return;
        }
      }
    }
    else{
      for(var objKey in this.state.jwtCredentials)
      {
        if (this.state.jwtCredentials?.[objKey] === "") {
          this.setState({
            displayToast: true,
            messageToast: objKey +"is required field",
            severity: "error",
          });
          return;
        }
      }
    }
    try {
      const allOptions = {
        url: serverUrl + `projects/` + id,
        body: this.state.jwtCredentials,
      };
      await updateData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Jwt credentials update Successfully",
        severity: "success",
      });
      this.loadAllProjects();
      this.hideNewProj();
    } catch (e) {
      const errorObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  }

  removeProject = (index, id) => {
    this.setState({
      confirmAlert: true,
      projectId: id,
    });
  };

  submitProjectName = () => {
    if (this.state.projectName) {
      this.confirmBtnDelete(this.state.projectId, this.state.projectName);
    } else {
      this.setState({
        displayToast: true,
        messageToast: "Project Name Required..!",
        severity: "error",
      });
    }
  };

  cancelBtn = () => {
    this.setState({ confirmAlert: false });
  };

  cancelBtn1 = () => {
    this.setState({ confirmAlertChild: false });
  };

  cancelDelte = () => {
    this.setState({ projectNamePopup: false, confirmAlert: false, masterKeyPopup:false });
  };

  confirmBtn = () => {
    this.setState({ projectNamePopup: true, confirmAlert: false });
  };

  handleProjectName = (e) => {
    this.setState({ projectName: e.target.value });
  };

  showHideEye = () => {
    this.setState({
      showHideEye: !this.state.showHideEye,
      masterKeyType: this.state.masterKeyType === 'password' ? 'text' : 'password'
    })
  }

  handleMasterKeyInput = (e) => {
    this.setState({ masterKey: e.target.value });
  }

  backendPostCall = (data) => {
    this.setState({
      projectId: data.id,
      masterKeyPopup:true,
    })
  }

  submitMasterKey = async() => {
    try{
      const allOptions = {
        url: parseServerUrl+ 'create-backend/' + this.state.projectId,
        body: {"masterKey": this.state.masterKey},
      };
      await saveData(allOptions);
      this.setState({
        displayToast: true,
        messageToast: "Project Backend Created Successfully",
        severity: "success",
        masterKeyPopup:false,
      });
    }
    catch(e){
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  }

  confirmBtn1 = () => {
    this.removeUserFromProject(
      this.state.projectIdToRemoveUser,
      this.state.removeUserEmail
    );
  };

  confirmBtnDelete = async (id, pName) => {
    this.setState({ loadingCard: true });
    try {
      const allOption = {
        url: serverUrl + 'projects/' + id,
        params: { name: pName },
      };
      await deleteData(allOption);
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "Project Deleted Successfully",
        severity: "success",
        projectNamePopup: false,
        confirmAlert: false,
        projectId:'',
      });
      this.loadAllProjects();
    } catch (e) {
      const errorObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  };

  deleteUser = (i, projId, email) => {
    this.setState({
      confirmAlertChild: true,
      projectIdToRemoveUser: projId,
      removeUserEmail: email,
    });
  };

  projectTitleEnter = async (evt, index, id) => {
    let projects = [...this.state.allProjects];
    projects[index].name = evt.target.value;
    if (evt.keyCode === 13) {
      try {
        const allOption = {
          url: serverUrl +'projects/'+ id,
          body: { name: projects[index].name },
        };
        await updateData(allOption);
        this.setState({
          loadingCard: false,
          displayToast: true,
          messageToast: "Project Name Updated Successfully",
          severity: "success",
        });
        projects[index].inplaceEdit = !projects[index].inplaceEdit;
      } catch (e) {
        const errorObj = FindError(e);
        this.setState({
          displayToast: true,
          messageToast: errorObj.message,
          severity: "error",
          confirmAlert: false,
          loadingCard: false
        });
      }
    }
    this.setState({ allProjects: projects });
  };

  projectTitleBlur = async (index, id) => {
    let projects = [...this.state.allProjects];
    try {
      const allOption = {
        url: serverUrl +'projects/'+ id,
        body: { name: projects[index].name },
      };
      await updateData(allOption);
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "Project Name Updated Successfully",
        severity: "success",
      });
      projects[index].inplaceEdit = !projects[index].inplaceEdit;
    } catch (e) {
      const errorObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
    this.setState({ allProjects: projects });
    projects[index].inplaceEdit = !projects[index].inplaceEdit;
  };

  setToggle = async (index, id, value) => {
    let projects = [...this.state.allProjects];
    if (value) {
      projects[index].active = false;
    } else {
      projects[index].active = true;
    }
    try {
      const allOption = {
        url: serverUrl + 'projects/' + id,
        body: { active: projects[index].active },
      };
      await updateData(allOption);
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "Project Status Updated Successfully",
        severity: "success",
      });
    } catch (e) {
      const errorObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
    this.setState({ allProjects: projects });
  };

  newUserBLock = () => {
    this.setState({assignUserBlock: true})
  };

  changeConfigTab = (val) => {
    this.setState({ defaultInnerTab: val });
  };

  autoCompleteUser = (e) => {
    let userstoAdd = e.currentTarget.textContent;
    this.setState({ assignNewUserEmail: userstoAdd });
  };

  handleUserRole = async (e, i, projectData) => {
    let userstoAdd = [...this.state.assignedUsers];
    userstoAdd[i].role = e.target.value;
    let roleValObj = this.state.allRoles.find((d) => d.name === e.target.value);
    const convertRoleArray = roleValObj.roles.map((d) => d.id);
    try{
      const allOption = {
        url: serverUrl + `projects/roles/`+projectData.id+`/role-data`,
        body: convertRoleArray
      }
      const roleTotalData = await saveData(allOption);
      const updateRoleUserObj = {
        email: userstoAdd[i].email,
        roleValues: roleTotalData.data,
        pId: projectData.id
      }
      this.updateRoleToUser(updateRoleUserObj)
    }
    catch(e){
      const errorObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
    this.setState({ assignedUsers: userstoAdd });
  };

  updateRoleToUser = async(obj) => {
    try{
      const bodyObj = {
        email: obj.email,
        roleValues: obj.roleValues
      }
      const allOption = {
        url: serverUrl + `projects/roles/`+obj.pId+`/assign-user`,
        body: {...bodyObj}
      }
     await saveData(allOption);
      this.setState({
        displayToast: true,
        messageToast: "Role added to the user",
        severity: "success",
      })
    }
    catch(e){
      const errorObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  }

  removeUserFromProject = async (id, email) => {
    this.setState({ loadingCard: true });
    try {
      const allOption = {
        url: serverUrl + `projects/` + id + `/users/` + email,
      };
      await deleteData(allOption);
      this.getProjectAssignedUsers(id);
      this.setState({
        confirmAlertChild: false,
        loadingCard: false,
        displayToast: true,
        messageToast: "User Removed Successfully",
        severity: "success",
      });
    } catch (e) {
      const errorObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
      this.setState({ confirmAlertChild: false, loadingCard: false });
    }
  };

  addNewUsertoProject = async (item) => {
    const userEmail = this.state.assignNewUserEmail;
    try {
      const allOption = {
        url: serverUrl + `projects/`+item.id+`/users/` + userEmail,
      }
      await saveData(allOption);
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "User added to the project",
        severity: "success",
      });
      this.getProjectAssignedUsers(item.id);
    }
    catch(e){
      const errorObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false
      });
    }
  }

  assignUserToProject = async (id) => {
    this.state.assignedUsers.map((x) => delete x.newAssignUser);
    let obj = {
      clientProject: id,
      users: [...this.state.assignedUsers],
    };
    try {
      const allOption = {
        url: serverUrl + `projects/`+id+`/users/`,
        body: { ...obj },
      };
      await saveData(allOption);
      this.setState({
        displayToast: true,
        messageToast: "Role Assigned to user",
        severity: "success",
      });
      this.getProjectAssignedUsers(id);
    } catch (e) {
      const errorObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  showInPlaceEdit = (index) => {
    let projects = [...this.state.allProjects];
    projects[index].inplaceEdit = !projects[index].inplaceEdit;
    this.setState({ allProjects: projects });
  };

  // function to show input field for the required card field
  InputPlaceEdit = (index) => {
    this.showInPlaceEdit(index);
  };

  setProjectLanguages = async (id) => {
    if (this.state.projectLanguages[0]) {
      this.setState({ loadingCard: true });
      const langPayload = { userEmail:localStorage.getItem('botUser'), languages: this.state.projectLanguages };
      try {
        const allOptions = {
          url: serverUrl + "languages/" + id,
          body: langPayload,
        };
        const res = await saveData(allOptions);
        if (res.status === 200) {
          this.setState({
            loadingCard: false,
            displayToast: true,
            messageToast: "Project Language Configured Successfully",
            severity: "success",
          });
          this.getProjectLangs(id);
          this.cancelExpand();
        }
      } catch (err) {
        const errorObj = FindError(err)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
        loadingCard: false,
      });
      }
    } else {
      this.setState({
        loadingCard: false,
        displayToast: true,
        messageToast: "Please Select at least one language",
        severity: "error",
      });
    }
  };

  selectLanguage = (lang, type) => {
    if (type)
      this.setState({
        projectLanguages: [...this.state.projectLanguages, lang],
      });
    else {
      const index = this.state.projectLanguages
        .map((lg) => lg.code)
        .indexOf(lang.code);
      const arr = this.state.projectLanguages;
      arr.splice(index, 1);

    this.setState({ projectLanguages: arr });

    }
  };

  globalLangAdd = async () => {
    const globalLang = {
      languageData: this.state.languageOptions.find(
        (lg) => lg.name === this.state.chosenLang
      ),
    };
    try {
      const allOptions = {
        url: serverUrl + `languages`,
        body: this.state.languageOptions.find(
          (lg) => lg.name === this.state.chosenLang
        ),
      };
      const langResp = await saveData(allOptions);
      if (langResp.status === 200) {
        this.setState({
          loadingCard: false,
          displayToast: true,
          messageToast: "Language Added Successfully",
          severity: "success",
          chosenLang: "",
          languageOptions: [],
        });
        this.languageList();
        this.getProjectLangs();
      }
    } catch (err) {
      const errorObj = FindError(err)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  // Close Toast
  closeToast = () => {
    this.setState({ displayToast: false });
  };

  handleNonSocialSelectDD = (event) => {
    this.setState({selectedNonSocialData: event.target.value});
    this.conversationFormBuilder.patchValue({});
  }

  handleChildCallBack = (data) => {
    this.setState({jwtCredentials: data})
  }

  nextPage = () => {
    let PreviousSetNo = this.state.pageNo + 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsmet = {
      page: (PreviousSetNo-1) * Number(localStorage.getItem('rowPerPage')),
    };
    this.loadAllProjects(paramsmet);
  };

  // function to go to previous Page of Enterprise service
  previousPage = () => {
    let PreviousSetNo = this.state.pageNo - 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsmet = {
      page: (PreviousSetNo-1) * Number(localStorage.getItem('rowPerPage')),
    };
    this.loadAllProjects(paramsmet);
  };

  rowPerPageChange = (e) => {
    localStorage.setItem("rowPerPage", e.target.value);
    const paramsmet = {
      perPage: e.target.value,
    };
    this.loadAllProjects(paramsmet);
  };

   // to copy the url from card
   copyText = (e) => {
    let copyText = e.currentTarget.textContent;
    try {
      navigator.clipboard.writeText(copyText);
      this.setState({
        displayToast: true,
        messageToast: "Copied to Clipboard",
        severity: "success",
      });
    } catch (err) {
      this.setState({
        displayToast: true,
        messageToast: "Failed to Copy",
        severity: "error",
      });
    }
  };


  // table header block
  bannerHeader() {
    return (
      <div className="display-flex table_head font13 fontWeight700 cardSpaceArrow">
        <div className="table_grid">
          <p>ID </p>
        </div>
        <div className="table_grid">
          <p>Project Name </p>
        </div>
        <div className="table_grid">
          <p>No of Users</p>
        </div>
        <div className="table_grid">
          <p>Created By</p>
        </div>
        <div className="table_grid">
          <p>Created on</p>
        </div>
        <div className="table_grid">
          <p>Last Updated on</p>
        </div>
        <div className="table_grid">
          <p>No of Journeys</p>
        </div>
        <div className="table_grid">
          <p>Enable / Disable</p>
        </div>
      </div>
    );
  }

  // UI MODULES for single card
  bannerCard = (item, index) => {
    return (
      <div className="display-flex text_black font13 w100 pr15 pl15">
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        <div className="table_grid">
          <p className="overflowTextdots" style={{width:'104px'}} id={`projectOnCopy${index}`} onClick={this.copyText}>
            {item.id}
          </p>
        </div>
        <div className="table_grid">
          {item.inplaceEdit !== false ? (
            <div>
              <p
                className={`in-block ${
                  this.state.permissions ? "" : "disable-click"
                }`}
                onClick={() => this.InputPlaceEdit(index)}
              >
                {item.name}
              </p>
              &nbsp;
              <img
                alt="editIcon"
                src={jrnyEdit}
                className="pointer-coursor"
                onClick={() => this.showInPlaceEdit(index)}
              />
            </div>
          ) : (
            <input
              type="text"
              value={item.name}
              name="name"
              style={{
                height: "34px",
                border: "1px solid #999",
                borderRadius: "8px",
              }}
              onChange={(e) => this.projectTitleEnter(e, index, item.id)}
              onBlur={() => this.projectTitleBlur(index, item.id)}
              onKeyDown={(e) => this.projectTitleEnter(e, index, item.id)}
            />
          )}
        </div>
        <div className="table_grid">
          <p className="text-center">
            {item.userCount}
          </p>
        </div>
        <div className="table_grid">
          <p className="">{item.createdBy} </p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {moment(item.createdOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <p className="flex" style={{ marginTop: "8px" }}>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span
              style={{
                color: "#000",
                marginTop: "6px",
              }}
            >
              {item.modifiedOn ? moment(item.modifiedOn).format("DD-MMM-YYYY") : '~N/A~'}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <p className="text-center">{item.journeys}</p>
        </div>
        <div className="table_grid">
          <div
            className={`toggle-parent-modeller ${
              item.active ? "" : "inactiveToggle"
            }`}
            onClick={this.setToggle.bind(this, index, item.id, item.active)}
            style={{ marginTop: "9px" }}
          >
            <div
              className={` ${
                item.active ? "toggle-button" : "disabled-toggle"
              }`}
            >
            </div>
          </div>
        </div>
        <img
          src={deletewhiteIcon}
          id={`deleteProject${index}`}
          alt="trash Icon"
          onClick={() => this.removeProject(index, item.id)}
          className="deleteIcon"
        />
      </div>
    );
  };

  render() {
    return (
      <div className="parent mt10">
        {this.state.confirmAlert === true && (
          <ConfirmAlert
            indexValue={this.state.slabsIndex}
            mainIndex={this.state.apiServicesNIndex}
            onConfirmBtn={this.confirmBtn}
            onCancelBtn={this.cancelBtn}
            heading="Are you sure ?"
            paragraph="If you proceed you will loose the project"
          />
        )}
        {this.state.confirmAlertChild === true && (
          <ConfirmAlert
            indexValue={this.state.slabsIndex}
            mainIndex={this.state.apiServicesNIndex}
            onConfirmBtn={this.confirmBtn1}
            onCancelBtn={this.cancelBtn1}
            heading="Are you sure ?"
            paragraph="If you proceed user will be removed from this project"
          />
        )}
        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <h4 className="m0">
            Projects <span className="label_pink">Assign Projects</span>
          </h4>
          <div>
            <button
              className="sxp-btn btn-primary"
              onClick={this.getlanguageOptions.bind(this)}
              style={{ marginRight: "10px" }}
            >
              <AddIcon className="vertical-7" /> Language Config
            </button>
            <button
              className="sxp-btn btn-primary"
              id="addNewProject"
              onClick={this.addNewProject}
            >
              <AddIcon className="vertical-7" /> Projects
            </button>
          </div>
        </div>

        {this.state.projectNamePopup ? (
          <>
            <div className="overlay"></div>
            <div className="auth_popup">
              <h5>Please Enter Project Name which you want to delete</h5>
              <input
                type="text"
                placeholder="Enter Project Name"
                onChange={this.handleProjectName}
              />
              <div className="flex">
                <button
                  className="confirmBtn"
                  onClick={this.submitProjectName}
                  style={{ marginRight: "5px" }}
                >
                  Submit
                </button>
                <button
                  className="cancelBtn"
                  onClick={this.cancelDelte}
                  style={{ marginLeft: "5px", backgroundColor: "#ec5b56" }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        ) : null}

        {this.state.masterKeyPopup ? (
          <>
            <div className="overlay"></div>
            <div className="auth_popup">
              <h5>Please Enter Master key to add backend Server</h5>
              <div className="pos-rel masterKeyEye">
                <input type={this.state.masterKeyType} placeholder="Enter Master key" onChange={this.handleMasterKeyInput}/>
                <div className="handleEye" onClick={this.showHideEye}>
                  {this.state.showHideEye ?
                  <img src={eyeIcon} /> :
                  <img src={eyeBlockIcon} style={{height:'15px'}}/>
                  }
                  </div>
              </div>
              <div className="flex">
                <button
                  className="confirmBtn"
                  onClick={this.submitMasterKey}
                  style={{ marginRight: "5px" }}
                >
                  Submit
                </button>
                <button
                  className="cancelBtn"
                  onClick={this.cancelDelte}
                  style={{ marginLeft: "5px", backgroundColor: "#ec5b56" }}
                >
                  Cancel
                </button>
            </div>
            </div>
          </>
        ):null}

        {this.state.newVariable ? (
          <div className="formField projectCreateNew pl0">
            <FieldGroup
              control={this.conversationFormBuilder}
              render={({ invalid, value }) => (
                <form
                  className="newVar"
                  onSubmit={(e) => this.createProjectForm(e, value)}
                  // style={{ justifyContent: "center" }}
                >
                  <h4 className="varHead">Create a project: </h4>
                  <div className="flex">
                    <FieldControl
                      name="name"
                      options={{ validators: Validators.required }}
                      render={({ handler, touched, hasError }) => (
                        <div className="">
                          <input
                            {...handler()}
                            placeholder="Project Name"
                            className={`${
                              touched && hasError("required") && "errorInput"
                            }`}
                          />
                          <span
                            className={`${
                              touched && hasError("required") && "errorFieldMsg"
                            }`}
                          >
                            {touched && hasError("required") && "Required Filed"}
                          </span>
                        </div>
                      )}
                    />
                    <FieldControl
                      name="description"
                      options={{ validators: Validators.required }}
                      render={({ handler, touched, hasError }) => (
                        <div className="">
                          <input
                            {...handler()}
                            placeholder="Project Description"
                            className={`${
                              touched && hasError("required") && "errorInput"
                            }`}
                          />
                          <span
                            className={`${
                              touched && hasError("required") && "errorFieldMsg"
                            }`}
                          >
                            {touched && hasError("required") && "Required Filed"}
                          </span>
                        </div>
                      )}
                    />
                  </div>
                  <div className="jwtDiv">
                    <h5 className="jwtc">Jwt Credentials: ( non-social channel authentication )</h5>
                    <div className="flex" style={{flexWrap:'wrap'}}>

                      <div className="pos-rel">
                      <Select
                        className="sxp-defaultDropDown_filed mt0"
                        style={{
                          margin: "15px 5px 0 5px",
                          width: "220px",
                        }}
                        onChange={this.handleNonSocialSelectDD}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                      >
                        <MenuItem value="" disabled>Select Non-Social OAuth</MenuItem>
                          {this.state.nonSocialData.map((data, i) => (
                            <MenuItem key={i} value={data.name}>{data.name}</MenuItem>
                          ))
                        }
                      </Select>
                      {this.state.selectedNonSocialData ? null : (
                        <FormHelperText className="roleSelectPlaceholder"> Select Non-Social OAuth </FormHelperText>
                      )}
                    </div>
                    {this.state.selectedNonSocialData === 'OAuth 2.0' && (
                      <OAuthTwoDotO handleJWTFormCallBack= {this.handleChildCallBack}/>
                    )}

                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      className={`sxp-btn btn-primary ${
                        invalid && "disablePagBtn"
                      }`}
                      type="submit"
                      style={{ height: "34px", marginRight: "10px" }}
                      disabled={invalid}
                    >
                      Save
                    </button>
                    <button
                      id="hideNewProject"
                      className="sxp-btn btn-danger"
                      onClick={this.hideNewProj}
                      style={{ height: "34px" }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        ) : (
          ""
        )}

        {this.state.newLangConfig ? (
          <div
            className="flex newVar"
            style={{ justifyContent: "center", marginTop: "10px" }}
          >
            <h4 className="varHead">Configure a Language: </h4>
            <div className="pos-rel">
              <Select
                className="sxp-defaultDropDown_filed mt0"
                style={{ width: "220px", marginRight: "10px", zIndex: 1 }}
                value={this.state.chosenLang}
                onChange={(e) => {
                  this.setState({ chosenLang: e.target.value });
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {this.state.languageOptions.map((data) => (
                  <MenuItem value={data.name} key={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
              {this.state.chosenLang.length ? (
                ""
              ) : (
                <FormHelperText className="langSelectPlaceholder">
                  Select Language
                </FormHelperText>
              )}
            </div>
            <button
              className="sxp-btn btn-primary"
              type="submit"
              style={{ height: "34px", marginRight: "10px" }}
              onClick={this.globalLangAdd}
            >
              Save
            </button>
            <button
              className="sxp-btn btn-danger"
              onClick={() => this.setState({ newLangConfig: false })}
              style={{ height: "34px" }}
            >
              Cancel
            </button>
          </div>
        ) : null}

        <div className="projectsDiv">
          {this.bannerHeader()}
          {this.state.allProjects.length ? (
            <div>
              {this.state.allProjects.map((item, index) => (
                <ExpansionPanel
                  className="CustomexpansionPannel"
                  key={item.id + index}
                  expanded={
                    this.state.expansionJrnyPanelOpen &&
                    this.state.journeyIndex === index
                  }
                >
                  <ExpansionPanelSummary
                    expandIcon={
                      <ExpandMoreIcon
                        className="iconExpand"
                        onClick={this.switchJrnyExpansionpanel.bind(
                          this,
                          index,
                          item.id
                        )}
                      />
                    }
                    style={{ order: -1 }}
                    className="boxShadow"
                  >
                    {this.bannerCard(item, index)}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="inner_expandBlock serviceBtnFooter">
                    <div>
                      <h5>SXP features used &nbsp;</h5>
                      <div className="sxp-features display-flex">
                        <div>
                          <p>No of Recommendations used</p>
                          <span>RECOMMENDATIONS: {item.recoCount}</span>
                        </div>
                        <div>
                          <p>No of Business Modules Used</p>
                          <span>BUSINESS MODULES : {item.bmCount} </span>
                        </div>
                        <div>
                          <p>AB Testing</p>
                          <span>AB TESTING : {item.abCount}</span>
                        </div>
                        <div>
                          <p>Existing Channels in the Project</p>
                          <span className="channelIcon">
                            CHANNELS
                              {item.channels && item.channels.length ? (
                              <>
                              {item.channels.map((channelData, channelIndex) => (
                                <>
                                {channelData.name === 'whatsapp' && (
                                  <img alt="Whatsapp icon" src={Whatsapp} />
                                )}
                                {channelData.name === 'facebook' && (
                                  <img alt="Whatsapp icon" src={fbmsngr} />
                                )}
                                {channelData.name === 'slack' && (
                                  <img alt="Whatsapp icon" src={slack} />
                                )}
                                {channelData.name === 'line' && (
                                  <img alt="Whatsapp icon" src={line} />
                                )}
                                {channelData.name === 'telegram' && (
                                  <img alt="Whatsapp icon" src={telegram} />
                                )}
                                {channelData.name === 'webchat' && (
                                  <img alt="Whatsapp icon" src={webchat} />
                                )}
                                </>
                              ))}
                              </>
                            ) : (
                              <>-</>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="projectDesc">
                        <span className="font600">Description : </span>
                        {item.descriptitemion}
                      </div>
                      <div className="profileTab tabsBorder">
                        <ul className="pl0 mb0">
                          {projectInnerTabs.map((d, i) => (
                            <li
                              key={i}
                              className={
                                d === this.state.defaultInnerTab
                                  ? "active-tab"
                                  : ""
                              }
                              onClick={() => this.changeConfigTab(d)}
                            >
                              {d}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        {this.state.defaultInnerTab === "Configure Roles" && (
                          <>
                            <div className="display-flex">
                              <h5 className="m0">Add roles to users</h5>
                              <div>
                                <button
                                  className="sxp-btn btn-primary mr5"
                                  onClick={this.newUserBLock}
                                >
                                  <AddIcon className="vertical-7" /> Assign Users
                                </button>
                                { item.hasBackendServer === false &&
                                <button
                                  className="sxp-btn btn-primary"
                                  onClick={()=>this.backendPostCall(item)}
                                >
                                  <AddIcon className="vertical-7" /> Add Backend Server
                                </button>
                                }
                              </div>
                            </div>

                            {this.state.assignUserBlock && (
                              <div className="flex">
                                <h4 className="varHead">Add User to project</h4>
                                <div className="pos-rel usersearchInput">
                                    <Autocomplete
                                      id="custom-input-demo"
                                      name="email"
                                      options={this.state.allUsersList
                                          .filter(a => a.email)
                                          .map((a) => a.email)}
                                      onChange={(e) =>
                                        this.autoCompleteUser(e)
                                      }
                                      renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                          <input
                                            style={{marginTop:'0'}}
                                            type="text"
                                            className="w100"
                                            placeholder="Enter User Email"
                                            {...params.inputProps}
                                          />
                                        </div>
                                      )}
                                    />
                                    <img
                                      alt="chevronDownIcon"
                                      src={chevronDown}
                                      style={{top:'7px'}}
                                      className="downArrrowChev"
                                    />
                                  </div>
                                  <button
                                    className="sxp-btn btn-primary"
                                    onClick={() => this.addNewUsertoProject(item)}
                                  > Assign User
                                  </button>
                              </div>
                            )}

                            <div className="userAddBLock">
                              {this.state.assignedUsers.length ? (
                                <>
                                  <div className="userAddHeader flex">
                                    <label>Name</label>
                                    <label>Roles</label>
                                  </div>
                                  {this.state.assignedUsers.map((d, i) => (
                                    <div className="flex" key={d.id}>
                                      <div className="pos-rel usersearchInput">
                                        <Autocomplete
                                          id="custom-input-demo"
                                          disabled
                                          value={d.email}
                                          name="email"
                                          options={this.state.allUsersList
                                              .filter(a => a.email)
                                              .map((a) => a.email)}
                                          onChange={(e) =>
                                            this.autoCompleteUser(e, i)
                                          }
                                          renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                              <input
                                                type="text"
                                                className="w100"
                                                placeholder="Enter User Email"
                                                {...params.inputProps}
                                              />
                                            </div>
                                          )}
                                        />
                                      </div>
                                      <div className="pos-rel">
                                        <Select
                                          className="sxp-defaultDropDown_filed mt0"
                                          value={d.role}
                                          style={{
                                            margin: "15px 5px 0 5px",
                                            width: "220px",
                                          }}
                                          onChange={(e) =>
                                            this.handleUserRole(e, i, item)
                                          }
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {this.state.allRoles.map(
                                            (data, i) => (
                                              <MenuItem key={i} value={data.name}>
                                                {data.name}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                        {d.role ? null : (
                                          <FormHelperText className="roleSelectPlaceholder">
                                            Select Role
                                          </FormHelperText>
                                        )}
                                      </div>
                                      <span
                                        className="deleteUserIcon"
                                        onClick={() =>
                                          this.deleteUser(i, item.id, d.email)
                                        } >
                                          <img
                                            src={DeleteIcon}
                                            alt="trash Icon"
                                          />
                                      </span>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <p className="text-center noUserText">
                                  Current No User Added, Click "+ Assign User"
                                  to add
                                </p>
                              )}
                            </div>
                          </>
                        )}

                        {this.state.defaultInnerTab ===
                          "Configure Languages" && (
                          <>
                            <div className="display-flex">
                              <h5 className="m0">
                                Configure Project Languages
                              </h5>
                            </div>
                            <div className="userAddBLock">
                              <div className="">
                                <div className="userAddHeader">
                                  <label
                                    className="in-block mt20"
                                    style={{ width: "80px" }}
                                  >
                                    Select
                                  </label>
                                  <label
                                    className="in-block mt20"
                                    style={{ width: "80px" }}
                                  >
                                    Language
                                  </label>
                                </div>
                                {this.state.langArray.map((lang, i) => (
                                  <div key={lang.id}>
                                   <Checkbox
                                      checked={this.state.projectLanguages.length >= 1
                                        ? this.state.projectLanguages.find(
                                            (data) => data.code === lang.code
                                          )
                                        : false}
                                      onClick={(event) => {
                                        this.selectLanguage(
                                          lang,
                                          event.target.checked
                                        );
                                      }}
                                      inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    <span>{lang.name}</span>
                                  </div>
                                ))}
                              </div>
                              <div className="buttonClmns mt20">
                                <button
                                  className="sxp-btn btn-primary"
                                  type="submit"
                                  style={{
                                    height: "34px",
                                    width: "220px",
                                    margin: "0 5px",
                                  }}
                                  onClick={() =>
                                    this.setProjectLanguages(item.id)
                                  }
                                >
                                  Save
                                </button>
                                <span
                                  className="sxp-btn btn-danger"
                                  onClick={() => this.cancelExpand(index)}
                                  style={{
                                    height: "34px",
                                    width: "220px",
                                    textAlign: "center",
                                    margin: "0 5px",
                                    padding: "9px 0",
                                    display: "inline-block",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Cancel
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        {this.state.defaultInnerTab === "Configure Jwt Credentials" && (
                          <>
                           <h5 className="m0"> Configure JWT Credentials </h5>
                            <div className="pos-rel">
                              <Select
                                className="sxp-defaultDropDown_filed mt0"
                                value={item.nonSocialChannelAuthType}
                                style={{
                                  margin: "15px 5px 0 5px",
                                  width: "220px",
                                }}
                                onChange={this.handleNonSocialSelectDD}
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                <MenuItem value="" disabled>Select Non-Social OAuth</MenuItem>
                                  {this.state.nonSocialData.map((data, i) => (
                                    <MenuItem key={i} value={data.name}>{data.name}</MenuItem>
                                  ))
                                }
                              </Select>
                            {item.nonSocialChannelAuthType ? null : (
                              <FormHelperText className="roleSelectPlaceholder"> Select Non-Social OAuth </FormHelperText>
                            )}
                            </div>
                            <OAuthTwoDotO jwtData={item.nonSocialChannelAuthCredentials} handleJWTFormCallBack = {this.handleChildCallBack}/>
                            <div className="buttonClmns text-center mt20">
                                <button
                                  className="sxp-btn btn-primary"
                                  type="submit"
                                  style={{
                                    height: "34px",
                                    width: "220px",
                                    margin: "0 5px",
                                  }}
                                  onClick={() =>this.updateJWTData(item.id)}
                                >
                                  Save
                                </button>
                                <span
                                  className="sxp-btn btn-danger"
                                  onClick={() => this.cancelExpand(index)}
                                  style={{
                                    height: "34px",
                                    width: "220px",
                                    textAlign: "center",
                                    margin: "0 5px",
                                    padding: "9px 0",
                                    display: "inline-block",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Cancel
                                </span>
                              </div>
                          </>
                        )}

                        {this.state.defaultInnerTab === "Configure Dashboard ID" && (
                          <div className="jwtFields">
                             <h5 className="m0"> Configure Dashboard ID </h5>
                             <input
                              type="number"
                              value={item.dashboardId}
                              className="hideInputNumberArrows"
                              placeholder="Configure Dashoard ID"
                              onChange={(e) => this.handleDashboardId(e, index)}
                            />
                             <div className="buttonClmns text-center mt20">
                                <button
                                  className="sxp-btn btn-primary"
                                  type="submit"
                                  style={{
                                    height: "34px",
                                    width: "220px",
                                    margin: "0 5px",
                                  }}
                                  onClick={() =>this.updateProjectDashboard(item)}
                                >
                                  Save
                                </button>
                                <span
                                  className="sxp-btn btn-danger"
                                  onClick={() => this.cancelExpand(index)}
                                  style={{
                                    height: "34px",
                                    width: "220px",
                                    textAlign: "center",
                                    margin: "0 5px",
                                    padding: "9px 0",
                                    display: "inline-block",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Cancel
                                </span>
                              </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </div>
          ) : (
            <EmptyCard />
          )}
        </div>
        <Pagination
          pageNo={this.state.pageNo}
          totalPages={this.state.totalPages}
          rowPerPageChange={this.rowPerPageChange}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />
      </div>
    );
  }
}

export default Projects;
