import React from "react";
import { FormButtons } from "./formbuttons";
import { submit } from "./formActions";

export class UserRegister extends React.Component {
  state = {
      utterance:[{lang:'en'}],
  };

  static getDerivedStateFromProps(nextProps, currentState) {
    let propsState;
    nextProps.data.data.utterance = [ { lang: 'en', label: nextProps.data.data.utterance?.en ?? '' } ]
    if (
      currentState.formUniqueId &&
      currentState.formUniqueId === nextProps.formUniqueId
    ) {
      propsState = { ...(nextProps.data.data || {}), ...currentState };
    } else {
      propsState = { ...currentState, ...(nextProps.data.data || {}) };
    }
    propsState.formUniqueId = nextProps.formUniqueId;
    return propsState;
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.setState({});
  }
  handleTextInputChange = (e) => {
    let updatedState = { ...this.state };
    updatedState[e.target.name] = e.target.value;
    this.setState(updatedState);
  };

    handleQuestionChange = (e) => {
        let arr = [...this.state.utterance];
        arr[0].label = e.target.value;
        this.setState({utterance: arr})
    };

  render() {
    return (
      <form className="form-style" onSubmit={(e) => submit(e, this.props, this.state)}>
        <div>
          <label> Name </label>
          <input
            type="text"
            name="name"
            defaultValue={this.state.name}
            onChange={this.handleTextInputChange}
            key={this.state.formUniqueId}
            required
          />
        </div>
        <div>
          <label> User ID Reference </label>
          <input
            type="text"
            name="userRegisterRef"
            defaultValue={this.state.userRegisterRef}
            onChange={this.handleTextInputChange}
            key={this.state.formUniqueId}
            required
          />
        </div>
        <label> Question </label>
        <input
          type="text"
          name="utterance"
          defaultValue={this.state.utterance[0].label}
          onChange={this.handleQuestionChange}
          key={this.state.formUniqueId}
          required
        />

        <input
          type="text"
          name="isUserRegistrationComponent"
          defaultValue={"true"}
          hidden
        />
        <FormButtons formProps={this.props} />
      </form>
    );
  }
}
