import React, { Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { Link } from "react-router-dom";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import { createStore } from "redux";
import { Provider } from "react-redux";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmAlert from "../../commons/uicomponents/confirm";
import PositionedSnackbar from "../../commons/uicomponents/toast";
import ImportDialog from "../../commons/uicomponents/importDialog";
import {
  deleteData,
  getData,
  getSingleData,
  saveData,
  updateData,
} from "../../dataProvider/apiHandler";
import "./modeler.scss";
import LoaderSpinner from "../../commons/uicomponents/loader";
import VersionSelectDropDown from "../../commons/versions-dropdown";
import EmptyCard from "../../commons/uicomponents/emptycard";
import Pagination from "../../commons/pagination";
import sortIcon from "../../assets/images/sort.png";
import sortUpIcon from "../../assets/images/sorttop.png";
import sortDownIcon from "../../assets/images/sortdown.png";
import jrnyEdit from "../../assets/images/jrny-edit.png";
import calender from "../../assets/images/calender.png";
import filter from "../../assets/images/filter_list1.png";
import cloud from "../../assets/images/cloud_download.png";
import cloudEnable from "../../assets/images/cloud_upload.png";
import cloudUp from "../../assets/images/cloud_upload1.png";
import selectmode1 from "../../assets/images/selectmode1.png";
import selectmode2 from "../../assets/images/selectmode2.png";
import Whatsapp from "../../assets/images/watsapp.png";
import fbmsngr from "../../assets/images/fbmsngr.png";
import slack from "../../assets/images/slack.png";
import line from "../../assets/images/line.png";
import telegram from "../../assets/images/telegram.png";
import webchat from "../../assets/images/webchat.png";
import deletewhiteIcon from "../../assets/images/deletewhite.png";
import CloseTheme from "../../assets/images/close-theme.png";
import closeIcon from "../../assets/images/close.png";
import reducer from "./reducers";
import { Filter } from "./forms/filterForm";
import { ExportIndividual, exportJourneys, Import } from "./api/api";
import Conversation from "./conversation";
import { ConversationFlow } from "./canvas";
import MultipleSelect from "./channelMultiselect";
import fileDownload from "js-file-download";
import FindError from "../../dataProvider/errorHandler";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

export const store = createStore(reducer);

const serverUrl = window._env_.REACT_APP_SXP_URL;

class Modeler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journeys: [],
      expansionJrnyPanelOpen: false,
      journeyIndex: "",
      displayconv: false,
      conversationID: "",
      newConv: false,
      convList: false,
      singleConversation: {},
      convform: "",
      displayFlow: false,
      confirmAlert: false,
      displayToast: false,
      deleteJrnyId: "",
      displayFilter: false,
      sortNormal: true,
      sortUp: false,
      sortDown: false,
      sortJName: "",
      sortUName: "",
      enableExport: false,
      selectedFile: "",
      importsuccess: false,
      selectedJourneys: [],
      importedJrnys: [],
      loadingCard: false,
      journeyListForSearch: [],
      importedDetails: {},
      pageNo: 1,
      totalPages: "",
      perPage: localStorage.getItem("rowPerPage"),
      filteredList: {},
      singleJou: false,
      showSingleJourney: [],
      allchannelsList: [],
      checkedAll: false,
      channels: [
        "Select All",
        "Whatsapp",
        "Messenger",
        "Slack",
        "Line",
        "Telegram",
        "Phone Message",
      ],
      permissions: {},
      skipData: "",
      searchTextData:'',
      journeyUID:'',
      convHistory:[],
      convHistIndex:-1,
    };
    // this.onEditJrnyTitle = this.onEditJrnyTitle.bind(this);
    this.conversation = React.createRef();
  }

  componentDidMount = () => {
    this.getJourneysList();
    this.getJourneyListForFilter();
    this.getChannels();
  };

  // EXPANSIONS HANDLERS
  switchJrnyExpansionpanel(index, currentJourn) {
    this.setState({ conversationID: "" });
    if (this.state.singleJou) {
      this.setState({
        expansionJrnyPanelOpen: false,
        journeyIndex: "",
        conversationID: "",
        singleJou: false,
        journey: null,
      });
      this.showAllJourney();
    } else {
      this.showSingleJourney(index);
      this.setConverHistory(currentJourn.id);
      this.setState({
        expansionJrnyPanelOpen: true,
        journeyIndex: index,
        convList: true,
        journeyUID:currentJourn.uid,
        conversationID: currentJourn.id,
        displayFlow: true,
      });
      this.getSingleConversation(currentJourn.id);
    }
  }

  showAllJourney = () => {
    const paramsmet = {
      // page: this.state.pageNo,
      perPage: this.state.perPage,
      paginate: true,
    };
    this.getJourneysList(paramsmet, this.state.filteredList);
  };

  showSingleJourney = (i) => {
    let singleJourLists = [...this.state.journeys];
    this.setState({
      journey: singleJourLists[i],
      singleJou: true,
    });
  };

  getJourneysList = async (paramsmet, filterData) => {
    try {
      this.setState({ loadingCard: true });
      const initialvals = {
        version: localStorage.getItem("version"),
        page: paramsmet?.page || this.state.skipData,
        perPage: localStorage.getItem("rowPerPage"),
        projectId: localStorage.getItem("pId"),
        task_status : filterData?.active === 'true' || 'false' ? filterData?.active : null,
        searchWord : this.state.searchTextData === '.*' ? null: this.state.searchTextData,
        searchKey: 'name'
      };
      let filterParams = filterData && filterData;
      paramsmet = {
        searchFilter: {
          ...(filterParams && filterParams.name && { name: filterParams.name }),
          ...(filterParams &&
            filterParams.active !== null && { active: filterParams.active }),
        },
        ...paramsmet,
        ...initialvals,
      };
      const allOption = {
        url: serverUrl + "tasks/" + localStorage.getItem("pId"),
        params: paramsmet,
      };
      let initialLoad = await getData(allOption);
      this.setState({
        journeys: initialLoad.data.data.docs,
        totalPages: Math.ceil(
          initialLoad.data.data.total / initialLoad.data.data.limit
        ),
        loadingCard: false,
      });
      this.setState({ loadingCard: false });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  getJourneyListForFilter = async () => {
    try {
      const allOption = {
        url: serverUrl + "tasks/" + localStorage.getItem('pId'),
        params: {
          version: localStorage.getItem("version"),
          projectId: localStorage.getItem("pId"),
        },
      };
      let initialLoad = await getData(allOption);
      this.setState({ journeyListForSearch: initialLoad.data.data.docs });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  getSingleConversation = async (id) => {
    try {
      const allOption = {
        url: serverUrl + "tasks/" + localStorage.getItem('pId') + '/'+ id,
        params: {
          version: localStorage.getItem("version"),
        },
      };
      let res = await getSingleData(allOption);
      this.setState({ singleConversation: res.data, displayconv: true });
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  setConverHistory = (id) => {
    let convrList = [...this.state.convHistory];

    const selected = this.state.convHistIndex !== -1 ? convrList[this.state.convHistIndex] : null;
    if(selected !== id){
      const slice = convrList.slice(0, this.state.convHistIndex + 1);
      slice.push(id);
      this.setState({
        convHistIndex : this.state.convHistIndex + 1,
        convHistory: slice
      });
    }
  }

  getChannels = async () => {
    this.setState({ loadingCard: true });
    try {
      const allOption = {
        url: serverUrl + "channel-config/" + localStorage.getItem('pId'),
        params: {
          projectId: localStorage.getItem("pId"),
        },
      };
      let initialLoad = await getData(allOption);
      this.setState({
        allchannelsList: initialLoad.data.data.docs.map((d) => d.name),
        loadingCard: false,
      });
    } catch (error) {
      const errorObj = FindError(error)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  closeToast = () => {
    this.setState({ displayToast: false });
  };

  fileSelected = async (e) => {
    let journeys = [],
      data = {};
    if (e.target.files && e.target.files.length) {
      try {
        let resp = await Import(e.target.files[0]);
        if (resp?.data?.success) {
          data.edges = resp.data.taskData.Edge.length;
          data.components = resp.data.taskData.Component.length;
          data.businessmodules = resp.data.taskData.BusinessFunction.length;
          this.getJourneysList();
          resp.data.taskData.Task.forEach((task) => {
            journeys.push(task.name);
            this.setState({
              importedJrnys: journeys,
              importedDetails: data,
              importsuccess: true,
            });
          });
        } else {
          this.setState({
            displayToast: true,
            severity: "error",
            messageToast: "Could not import task data",
            loadingCard: false,
          });
        }
      } catch (e) {
        this.setState({
          displayToast: true,
          severity: "error",
          messageToast: `Task data import failed due to error: ${e.message}`,
          loadingCard: false,
        });
      }
    }
  };

  checkAllSelected = () => {
    if (this.state.journeys.length === this.state.selectedJourneys.length) {
      this.setState({ checkedAll: true });
    } else {
      this.setState({ checkedAll: false });
    }
  };

  selectJourneysForExport = (index, id, e) => {
    let jrneys = [...this.state.journeys];
    let data = [...this.state.selectedJourneys];
    if (e.target.checked) {
      jrneys[index].checked = true;
      data.push(id);
    } else {
      jrneys[index].checked = false;
      let indexid = data.indexOf(id);
      if (indexid !== -1) {
        data.splice(indexid, 1);
      }
    }
    this.setState({ journeys: jrneys, selectedJourneys: data }, () =>
      this.checkAllSelected()
    );
  };

  selectAllJourneys = (e) => {
    const jrnys = [...this.state.journeys];
    let allJourneyIds = [];
    if (e.target.checked) {
      allJourneyIds = jrnys.map((id) => id.id);
    }
    this.setState({
      selectedJourneys: allJourneyIds,
      journeys: jrnys.map((x) => ({ ...x, checked: e.target.checked })),
      checkedAll: e.target.checked,
    });
  };

  cancelExport = () => {
    this.setState({ enableExport: false });
  };

  onExport = async () => {
    try {
      const exportResp = await exportJourneys([]);
      const version = localStorage.getItem("version");
      const PID = localStorage.getItem("pId");
      const filename =
        exportResp.headers.filename || version + "_" + PID + ".zip";
      fileDownload(exportResp.data, filename);
      this.setState({
        enableExport: false,
        displayToast: true,
        severity: "success",
        messageToast: "Exported Successfully",
      });
    } catch (e) {
      this.setState({
        enableExport: false,
        displayToast: true,
        severity: "error",
        messageToast: "Export unsuccessful: " + e.message,
      });
    }
  };

  onEnableExport = () => {
    this.setState({ enableExport: true });
  };

  onFilter = () => {
    this.setState({ displayFilter: !this.state.displayFilter });
  };

  onNewJourney = () => {
    this.setState({ newConv: true, convform: "new", singleConversation: {} });
  };

  onCloseForm = () => {
    if (this.state.singleJou) {
      return this.setState({ newConv: false });
    }
    this.setState({ newConv: false, singleConversation: {} });
  };

  onDisplayConvForm = (index, action, conversationID) => {
    if (action === "edit") {
      this.setState({ convform: "edit", newConv: true });
      this.getSingleConversation(conversationID);
    }
  };

  createJourneyApi = async (obj, otherObj) => {
    obj.selectedRecommendationTypes = otherObj;
    this.setState({ loadingCard: true });
    try {
      const allOption = {
        url: serverUrl + "tasks/" + localStorage.getItem('pId'),
        body: obj,
        pageType: "modeler",
      };
      let res = await saveData(allOption);
      let jrnys = [...this.state.journeys];
      jrnys.push(res.data);
      this.setState({
        journeys: jrnys,
        newConv: false,
        displayToast: true,
        severity: "success",
        messageToast: "Created Successfully",
        loadingCard: false,
      });
      this.onCloseForm();
    } catch (e) {
      const errObj = FindError(e);
      this.setState({
        displayToast: true,
        messageToast: errObj.status + ' :: ' + "Description and Intent Can't be blank ",
        severity: "error",
      });
    }
    this.setState({ loadingCard: false });
  };

  updateJourneyApi = async (data) => {
    const dataId = data.id;
    delete data.id;
    try {
      this.setState({ loadingCard: true });
      const allOption = {
        url: serverUrl + "tasks/" + localStorage.getItem('pId') + "/" + dataId,
        body: { ...data }
      };
      await updateData(allOption);
      this.setState({
        newConv: false,
        displayToast: true,
        messageToast: "Updated Successfully",
        severity: "success",
        loadingCard: false,
      });
      this.getJourneysList();
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
    this.setState({ loadingCard: false });
  };

  deleteJourney = async (id) => {
    try {
      this.setState({ loadingCard: true });
      const allOption = {
        url: serverUrl + "tasks/" + localStorage.getItem('pId') + "/"+id,
      };
      await deleteData(allOption);
      this.setState({
        displayToast: true,
        severity: "success",
        messageToast: "Deleted Successfully",
        loadingCard: false,
      });
      this.getJourneysList();
    } catch (e) {
      const errorObj = FindError(e)
      this.setState({
        displayToast: true,
        messageToast: errorObj.message,
        severity: "error",
        confirmAlert: false,
      });
    }
  };

  setToggle = (index, id, value) => {
    if (this.state.journey) {
      let jrnys = { ...this.state.journey };
      if (value) {
        jrnys.active = false;
      } else {
        jrnys.active = true;
      }
      this.updateJourneyApi({
        id: jrnys.id,
        active: jrnys.active,
        version: localStorage.getItem("version"),
      });
      this.setState({ journey: jrnys });
    } else {
      let jrnys = [...this.state.journeys];
      if (value) {
        jrnys[index].active = false;
      } else {
        jrnys[index].active = true;
      }
      this.updateJourneyApi({
        id: jrnys[index].id,
        active: jrnys[index].active,
        version: localStorage.getItem("version"),
      });
      this.setState({ journeys: jrnys });
    }
  };

  onDeleteJrny = (id) => {
    this.setState({ confirmAlert: true, deleteJrnyId: id });
  };

  onConfirmButtonClick = () => {
    this.setState({ confirmAlert: false });
    this.deleteJourney(this.state.deleteJrnyId);
  };

  // to close confirmation dialogue
  cancelBtn = () => {
    this.setState({ confirmAlert: false });
  };

  // PAGINATION OPERATIONS

  // to go to previousPage
  previousPage = async () => {
    let PreviousSetNo = this.state.pageNo - 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsmet = {
      page: (PreviousSetNo - 1) * Number(localStorage.getItem("rowPerPage")),
    };
    this.getJourneysList(paramsmet, this.state.filteredList);
  };

  // to go to next page
  nextPage = async () => {
    let PreviousSetNo = this.state.pageNo + 1;
    this.setState({ pageNo: PreviousSetNo });
    const paramsmet = {
      page: (PreviousSetNo - 1) * Number(localStorage.getItem("rowPerPage")),
    };
    this.getJourneysList(paramsmet, this.state.filteredList);
  };

  rowPerPageChange = async (e) => {
    localStorage.setItem("rowPerPage", e.target.value);
    this.setState({ perPage: e.target.value });
    const paramsmet = {
      page: this.state.pageNo,
      perPage: e.target.value,
      paginate: true,
    };
    this.getJourneysList(paramsmet, this.state.filteredList);
  };

  normalSort = async () => {
    this.setState({ loadingCard: true });
    const paramsmet = {
      paginate: true,
      page: this.state.pageNo,
      perPage: this.state.rowPageSize,
      sort: { name: "" },
    };
    this.getJourneysList(paramsmet, this.state.filteredList);
    this.setState({
      sortNormal: true,
      sortUp: false,
      sortDown: false,
      sortJName: "",
      loadingCard: false,
    });
  };

  ascSort = async () => {
    this.setState({ loadingCard: true });
    const paramsmet = {
      paginate: true,
      page: this.state.pageNo,
      perPage: this.state.rowPageSize,
      sort: { name: "ASC" },
    };
    this.getJourneysList(paramsmet, this.state.filteredList);
    this.setState({
      sortNormal: false,
      sortUp: true,
      sortJName: "ASC",
      sortDown: false,
      loadingCard: false,
    });
  };

  descSort = async () => {
    this.setState({ loadingCard: true });
    const paramsmet = {
      paginate: true,
      page: this.state.pageNo,
      perPage: this.state.rowPageSize,
      sort: { name: "DESC" },
    };
    this.getJourneysList(paramsmet, this.state.filteredList);
    this.setState({
      sortNormal: false,
      sortUp: false,
      sortDown: true,
      sortJName: "DESC",
    });
    this.setState({ loadingCard: false });
  };

  applyFilter = async (data) => {
    const paramsmet = {
      perPage: this.state.perPage,
      paginate: true,
    };
    this.setState({ filteredList: data });
    this.setState({searchTextData: data.name}, () =>
      this.getJourneysList(paramsmet, data)
    )
  };

  removeActiveFilter = async () => {
    let filterData = { ...this.state.filteredList };
    filterData["active"] = null;
    this.setState({ filteredList: filterData });
    this.applyFilter(filterData);
  };

  removeNamenActiveFilter = () => {
    let filterData = { ...this.state.filteredList };
    filterData["active"] = null;
    filterData["name"] = null;
    this.setState({ filteredList: filterData });
    this.applyFilter(filterData);
  };

  removeNameFilter = async () => {
    let filterData = { ...this.state.filteredList };
    filterData["name"] = null;
    this.setState({ filteredList: filterData });
    this.applyFilter(filterData);
  };

  selectedChannels = (data, i, id) => {
    if (this.state.journey) {
      let journeyList = { ...this.state.journey };
      journeyList.channels = data;
      this.setState({ journey: journeyList });
    } else {
      let journeyList = [...this.state.journeys];
      journeyList[i].channels = data;
      this.setState({ journeys: journeyList });
    }
  };

  onCloseImport = () => {
    this.setState({ importsuccess: false });
  };

  updateChannel = (data, i) => {
    let journeyList = [...this.state.journeys];
    if (this.state.journey) {
      let journeyList1 = { ...this.state.journey };
      this.updateJourneyApi({
        id: journeyList1.id,
        channels:journeyList1.channels,
        version: localStorage.getItem("version"),
      });
    } else {
      this.updateJourneyApi({
        id: journeyList[i].id,
        channels: journeyList[i].channels,
        version: localStorage.getItem("version"),
      });
    }
  };

  // function to show input field for the required card field
  showInPlaceEdit = (index) => {
    let jrnys = [...this.state.journeys];
    jrnys[index].inplaceEdit = !jrnys[index].inplaceEdit;
    this.setState({ journeys: jrnys });
  };

  // function to show input field for the required card field
  InputPlaceEdit = (index) => {
    this.showInPlaceEdit(index);
  };

  onEditJrnyTitle(evt, index) {
    var code = evt.keyCode || evt.which;
    let jrnys = [...this.state.journeys];
    jrnys[index].name = evt.target.value;
    this.setState({
      journeys: jrnys,
    });
    if (code === 13) {
      const updateObj = {
        name: evt.target.value,
        id: jrnys[index]?.id,
      };
      this.updateJourneyApi({...updateObj, version: localStorage.getItem("version")});
      jrnys[index].inplaceEdit = !jrnys[index].inplaceEdit;
      this.setState({
        journeys: jrnys,
      });
    }
  }

  journetTitleBlur(index) {
    let jrnys = [...this.state.journeys];
    const updateObj = {
      name: jrnys[index].name,
      id: jrnys[index]?.id,
    };
    this.updateJourneyApi({...updateObj, version: localStorage.getItem("version")});
    jrnys[index].inplaceEdit = !jrnys[index].inplaceEdit;
    this.setState({
      journeys: jrnys,
    });
  }

  callBackJName2 = (obj) => {
    this.getUIDsID(obj.value)
    this.loadRequiredJourney(obj)
  }

  getUIDsID = async(uid) => {
    try{
      const initialvals = {
        version: localStorage.getItem('version'),
        uid: uid,
      }
      const allOption = {
        url: serverUrl + "tasks/" + localStorage.getItem('pId'),
        params: initialvals,
      }
      let loadedData = await getData(allOption);
      this.setConverHistory(loadedData.data.data.docs[0].id);
    }
    catch(e){
      console.log(e)
    }
  }

  loadRequiredJourney = async(obj) => {
    const initialvals = {
      version: localStorage.getItem('version'),
      projectId:localStorage.getItem('pId'),
      ...(obj.value ? {uid: obj?.value} : {id:obj}),
    }
    const allOption = {
      url: serverUrl + "tasks/" + localStorage.getItem('pId'),
      params: initialvals,
    }
    let loadedData = await getData(allOption);
    this.setState({
      singleJou:true,
      journey: loadedData.data.data.docs[0],
      expansionJrnyPanelOpen: true,
      journeyIndex: 0,
      journeyUID:loadedData.data.data.docs[0].uid,
      conversationID: loadedData.data.data.docs[0].id,
      displayFlow: true,
    });
    this.getSingleConversation(loadedData.data.data.docs[0].id);
    this.setState({
      displayToast: true,
      severity: "success",
      messageToast: "Switched to journey :: " + loadedData.data.data.docs[0].name,
    })
  }

  undoConv = () => {
    let convList = [...this.state.convHistory];
    let id = '';
    if(!this.state.singleJou)
    {
      this.switchJrnyExpansionpanel(convList.length-1, this.state.journeys.filter(d=>d.id === convList[convList.length-1])[0]);
    }
    id = convList[this.state.convHistIndex - 1];
    this.setState({
      convHistIndex: this.state.convHistIndex - 1,
      expansionJrnyPanelOpen: true,
      singleJou:true,
      journeyIndex: 0
    });
    this.loadRequiredJourney(id)
    // }

  }

  redoConv = () => {
    let convList = [...this.state.convHistory];
    console.log(convList)
    let id = convList[this.state.convHistIndex + 1];
    this.setState({convHistIndex: this.state.convHistIndex+1});
    this.loadRequiredJourney(id);
  }

  cardHeader = () => {
    return (
      <div className="display-flex table_head font13 fontWeight700 cardFull cardSpaceArrow">
        {this.state.enableExport && (
          <div className="selectAllField">
            <label>Select All</label>
            <input
              type="checkbox"
              checked={this.state.checkedAll}
              onChange={this.selectAllJourneys}
            />
          </div>
        )}
        <div className="table_grid">
          <p className="ml0">
            Journey Title
            <span className="sortIconbg ">
              {this.state.sortNormal ? (
                <img
                  alt="NormalSortIcon"
                  src={sortIcon}
                  onClick={this.ascSort}
                />
              ) : null}
              {this.state.sortUp ? (
                <img
                  alt="DescSortIcon"
                  src={sortUpIcon}
                  onClick={this.descSort}
                />
              ) : null}
              {this.state.sortDown ? (
                <img
                  alt="AscSortIcon"
                  src={sortDownIcon}
                  onClick={this.normalSort}
                />
              ) : null}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <p>Created By</p>
        </div>
        <div className="table_grid">
          <p>Created on</p>
        </div>
        <div className="table_grid">
          <p>Last Modified on</p>
        </div>
        <div className="table_grid">
          <p>Billing Config</p>
        </div>
        <div className="table_grid">
          <p>Channels</p>
        </div>
        <div className="table_grid">
          <p>Action</p>
        </div>
        <div className="table_grid">
          <p>Enable / Disable</p>
        </div>
      </div>
    );
  };

  cardBanner = (item, index) => {
    return (
      <div
        className="display-flex text_black font13 w100 pr15 pl15"
        style={{ paddingLeft: "8px" }}
      >
        <LoaderSpinner loadingCard={this.state.loadingCard} />
        {this.state.enableExport ? (
          <div className="checkbox in-block">
            <input
              type="checkbox"
              id="checkbox2"
              name=""
              checked={item.checked}
              onClick={this.selectJourneysForExport.bind(this, index, item.id)}
            />
          </div>
        ) : null}
        <div className="table_grid">
          {item.inplaceEdit !== false ? (
            <div>
              <Tooltip title={item.name}>
                <p
                  id={`modelerName${index}`}
                  className={`ml0 in-block overflowTextdots ${
                    this.state.permissions ? "" : "disable-click"
                  }`}
                  style={{ maxWidth: "107px" }}
                  onClick={() => this.InputPlaceEdit(index)}
                >
                  {item.name}
                </p>
              </Tooltip>
              &nbsp;
              {localStorage.getItem('version') === 'WORKING' ? (
                <img
                  alt="editIcon"
                  src={jrnyEdit}
                  id={`modelerEditIcon${index}`}
                  style={{ verticalAlign: "14px" }}
                  className="pointer-coursor"
                  onClick={() => this.showInPlaceEdit(index)}
                />
              ) : null}
            </div>
          ) : (
            <input
              type="text"
              value={item.name}
              id={`modelerNameInput${index}`}
              name="name"
              style={{
                height: "34px",
                border: "1px solid #999",
                borderRadius: "8px",
              }}
              onChange={(e) => this.onEditJrnyTitle(e, index)}
              onBlur={() => this.journetTitleBlur(index)}
              onKeyDown={(e) => this.onEditJrnyTitle(e, index)}
            />
          )}
        </div>
        <div className="table_grid">
          <Tooltip title={item.createdBy ? item.createdBy : ''}>
            <p className="">{item.createdBy ? item.createdBy.split("@")[0] : '~N/A~'} </p>
          </Tooltip>
        </div>
        <div className="table_grid">
          <p>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span style={{ color: "#000", marginTop: "6px" }}>
              {moment(item.createdOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <p>
            <img src={calender} className="vertical-7" alt="calendar Icon" />
            <span style={{ color: "#000", marginTop: "6px" }}>
              {moment(item.modifiedOn).format("DD-MMM-YYYY")}
            </span>
          </p>
        </div>
        <div className="table_grid">
          <Link to={"billing-config"}>
            <button type="submit" className="config-btn">
              Config Link
            </button>
          </Link>
        </div>
        <div className="table_grid flex">
          <MultipleSelect
            jrnyId={item.id}
            defVal={item.channels}
            permissionClic={this.state.permissions}
            allChannels={this.state.allchannelsList}
            selectedChannels={(e) => this.selectedChannels(e, index, item.id)}
            updatejourneyChannel={(e) => this.updateChannel(e, index)}
          />
        </div>
        <div className="table_grid">
          <button
            className="config-btn"
            id={`editJourenyDetails${index}`}
            onClick={this.onDisplayConvForm.bind(this, index, "edit", item.id)}
          >
            Edit Details
          </button>
        </div>
        <div className="table_grid text-center">
          <div
            className={`toggle-parent-modeller ${
              item.active ? "" : "inactiveToggle"
            }`}
            id={`modelerActiveStatus${index}`}
            onClick={this.setToggle.bind(this, index, item.id, item.active)}
            style={{ marginTop: "9px" }}
          >
            <div
              className={` ${
                item.active ? "toggle-button" : "disabled-toggle"
              }`}
            ></div>
          </div>
        </div>
        {localStorage.getItem('version') === 'WORKING' &&
        <img
          src={deletewhiteIcon}
          id={`modelerDeleteIcon${index}`}
          alt="trash Icon"
          onClick={() => this.onDeleteJrny(item.id)}
          className="deleteIcon"
        />
            }
      </div>
    );
  };

  operationButtons = () => {
    return (
      <div className="action-btns flex">

        {this.state.convHistory.length > 1 ?
        <div className="arrowsSwitchJourney">
          <button onClick={this.undoConv} className={`${this.state.convHistIndex === 0 ? 'disablePagBtn' : ''}`}><ArrowRightAltIcon className="workAsLeftArrow"/></button>
          <button onClick={this.redoConv} className={`${this.state.convHistory.length === this.state.convHistIndex+1 ? 'disablePagBtn' : ''}`}><ArrowRightAltIcon /></button>
        </div>
        : null }

        <div className="Version_modeller_dropdwn" style={{marginRight:'5px'}}>
          <VersionSelectDropDown final={this.getJourneysList} />
        </div>
        <div className="flex-div">
          <label className="cloud-btn cloud-top">
            <img alt="cloudIcon" className="icons" src={cloud} id="modelerImportCloudIcon"/>
            <input type="file" onChange={this.fileSelected.bind(this)} id="modelerImportInput"/>
            Import
          </label>
        </div>

        {/*{this.state.enableExport ? (*/}
        {/*  <div class="pos-rel">*/}
        {/*    <button*/}
        {/*      id="modelerExportCloudIcon"*/}
        {/*      onClick={this.onExport.bind(this)}*/}
        {/*      className="cloud-btn-enable"*/}
        {/*    >*/}
        {/*      <img alt="cloudIcon2" className="icons" src={cloudEnable} />*/}
        {/*      Export*/}
        {/*    </button>*/}
        {/*    {this.state.enableExport ? (*/}
        {/*      <div className="makeBelowBtn">*/}
        {/*        <img alt="selectModeIcon" className="" src={selectmode2} />*/}
        {/*        <img*/}
        {/*          alt="closeModeIcon"*/}
        {/*          src={CloseTheme}*/}
        {/*          className="closeExpot"*/}
        {/*          onClick={this.cancelExport}*/}
        {/*          id="modelerCancelExport"*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    ) : null}*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <button*/}
        {/*    onClick={this.onEnableExport.bind(this)}*/}
        {/*    className="cloud-btn"*/}
        {/*    id="enableExportBtn"*/}
        {/*  >*/}
        {/*    <img alt="cloudIcon" className="icons" src={cloudUp} />*/}
        {/*    Export*/}
        {/*  </button>*/}
        {/*)}*/}

        <button
            onClick={this.onExport.bind(this)}
            className="cloud-btn"
            id="enableExportBtn"
        >
          <img alt="cloudIcon" className="icons" src={cloudUp} />
          Export
        </button>

        <button className="cloud-btn" id="onFilterBtn" onClick={this.onFilter.bind(this)}>
          <img alt="filterIcon" className="icons" src={filter} />
          Filter
        </button>
        <Filter
          currentFilters={this.state.filteredList}
          journeys={this.state.journeyListForSearch}
          displayFilter={this.state.displayFilter}
          applyFilter={this.applyFilter}
          onClose={this.onFilter}
          clearAllFilter={this.removeNamenActiveFilter}
        />
        {this.state.permissions ? (
          <button
            id="addANewJourney"
            className="sxp-btn btn-primary"
            onClick={this.onNewJourney.bind(this)}
          >
            <AddIcon className="vertical-7" /> Journey
          </button>
        ) : null}
      </div>
    );
  };

  displayAllJourney = () =>
    this.state.journeys
      ? this.state.journeys.map((item, index) => (
          <ExpansionPanel
            key={index}
            className="CustomexpansionPannel"
            expanded={
              this.state.expansionJrnyPanelOpen &&
              this.state.journeyIndex === index
            }
          >
            <ExpansionPanelSummary
              expandIcon={
                <ExpandMoreIcon
                  className="iconExpand"
                  id={`modelerExpandArrow${index}`}
                  onClick={this.switchJrnyExpansionpanel.bind(
                    this,
                    index,
                    item
                  )}
                />
              }
              style={{ order: -1 }}
              className="boxShadow"
            >
              {this.cardBanner(item, index)}
            </ExpansionPanelSummary>
          </ExpansionPanel>
        ))
      : null;

  displaySingleJourney = () => {
    const { journey } = this.state;
    return (
      <ExpansionPanel className="CustomexpansionPannel" expanded>
        <ExpansionPanelSummary
          className="boxShadow"
          expandIcon={
            <ExpandMoreIcon
              id={`singleModelerExpandArrow`}
              style={{ order: "-1" }}
              className="iconExpand"
              onClick={this.switchJrnyExpansionpanel.bind(this, 1, journey)}
            />
          }
        >
          {this.cardBanner(journey, 0)}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Provider store={store}>
            <ConversationFlow
              journeyId={this.state.conversationID}
              journeyUID={this.state.journeyUID}
              singleConversation={this.state.singleConversation}
              displayFlow={this.state.displayFlow}
              currentVersion={localStorage.getItem("version")}
              callBackJName2={this.callBackJName2}
            />
          </Provider>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    return (
      <div className="parent mt10">
        {this.state.confirmAlert == true && (
          <ConfirmAlert
            indexValue={this.state.recoIndex}
            onConfirmBtn={this.onConfirmButtonClick}
            onCancelBtn={this.cancelBtn}
            heading="Are you sure ?"
            paragraph="If you proceed you will loose all your Journey. Are you sure you want to delete your Journey "
          />
        )}

        <PositionedSnackbar
          display={this.state.displayToast}
          msg={this.state.messageToast}
          closeToast={this.closeToast}
          severity={this.state.severity}
        />
        <div className="display-flex">
          <h4 className="m0">
            Journey Modeler
            <span className="label_pink">
              Create and Edit Customer Journeys
            </span>
          </h4>
          {this.operationButtons()}
        </div>

        {this.state.enableExport ? (
          <div className="">
            <img alt="selectModeIcon" src={selectmode1} />
          </div>
        ) : null}

        <div className="predefinedFilter">
          <p>
            <span style={{ fontStyle: "italic" }}>Preview Filters</span>
            {this.state.filteredList &&
            this.state.filteredList.name == null ? null : (
              <span className="FilterBadge">
                {this.state.filteredList.name}
                <img
                  alt="removeIcon"
                  src={closeIcon}
                  onClick={this.removeNameFilter}
                />
              </span>
            )}
            {this.state.filteredList &&
            this.state.filteredList.active == null ? null : (
              <span className="FilterBadge">
                {this.state.filteredList.active == true ? "Active" : "InActive"}
                <img
                  alt="removeIcon"
                  src={closeIcon}
                  onClick={this.removeActiveFilter}
                />
              </span>
            )}
          </p>
        </div>

        {this.state.enableExport && (
          <span className="countLabel">
            {this.state.selectedJourneys.length} Journeys Selected to export
          </span>
        )}

        {this.cardHeader()}
        {this.state.importsuccess === true && (
          <ImportDialog
            onClose={this.onCloseImport}
            heading="1 New Journeys Successfully Imported"
            journeynames={this.state.importedJrnys}
            data={this.state.importedDetails}
          />
        )}

        <div className="">
          {this.state.newConv ? (
            <div>
              <div className="overlay" onClick={this.onCloseForm}></div>
              <Conversation
                closeForm={this.onCloseForm}
                convform={this.state.convform}
                singleConversation={this.state.singleConversation}
                createJourneyApi={this.createJourneyApi}
                updateJourneyApi={this.updateJourneyApi}
                alljourneys={this.state.journeyListForSearch}
                currentVersion={localStorage.getItem("version")}
              />
            </div>
          ) : null}
          {this.state.journeys.length ? (
            <div>
              {this.state.singleJou
                ? this.displaySingleJourney()
                : this.displayAllJourney()}
            </div>
          ) : (
            <EmptyCard />
          )}
        </div>

        <Pagination
          pageNo={this.state.pageNo}
          totalPages={this.state.totalPages}
          rowPerPageChange={this.rowPerPageChange}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />
      </div>
    );
  }
}

export default Modeler;
